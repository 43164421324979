import { Action, createReducer, on } from '@ngrx/store'
import {
    AuthStateInterface,
    initialAuthState,
} from '../interfaces/authState.interface'
import { loginSuccessAction, logoutUserAction } from './actions/login.actions'
import {
    refreshAuthenticatedUserDataAction,
    refreshCurrentUserAction,
    refreshUserPreferencesAction,
} from './actions/refresh.actions'
import { registerSuccessAction } from './actions/register.actions'

const authReducer = createReducer(
    initialAuthState,
    on(
        registerSuccessAction,
        (state, action): AuthStateInterface => ({
            ...state,
            currentUser: action.currentUser,
            isUserAuthenticated: true,
        })
    ),
    on(
        loginSuccessAction,
        (state, action): AuthStateInterface => ({
            ...state,
            currentUser: action.auth.currentUser,
            userPreferences: action.auth.userPreferences,
            isUserAuthenticated: true,
        })
    ),
    on(
        refreshCurrentUserAction,
        (state, action): AuthStateInterface => ({
            ...state,
            currentUser: action.currentUser,
            isUserAuthenticated: true,
        })
    ),
    on(
        refreshUserPreferencesAction,
        (state, action): AuthStateInterface => ({
            ...state,
            userPreferences: action.userPreferences,
        })
    ),
    on(
        refreshAuthenticatedUserDataAction,
        (state, action): AuthStateInterface => ({
            ...state,
            currentUser: action.auth.auth.currentUser,
            userPreferences: action.auth.auth.userPreferences,
            isUserAuthenticated: action.auth.authenticated,
        })
    ),
    on(
        logoutUserAction,
        (state, action): AuthStateInterface => ({
            ...state,
            userPreferences: initialAuthState.userPreferences,
            currentUser: initialAuthState.currentUser,
            isUserAuthenticated: initialAuthState.isUserAuthenticated,
        })
    )
)

export function reducerAuth(
    state: AuthStateInterface | undefined,
    action: Action
) {
    return authReducer(state, action)
}
