import { NewsInterface } from './news.interface'

export interface NewsStateInterface {
    newsList: NewsInterface[]
    error: any | null
}

export const initialNewsState: NewsStateInterface = {
    newsList: [],
    error: null,
}
