import { NgModule, isDevMode } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from 'src/app/app-routing.module'

//material ui
import { LayoutModule } from '@angular/cdk/layout'
import { MatButtonModule } from '@angular/material/button'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

//components
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { AppComponent } from 'src/app/app.component'
import { AboutComponent } from 'src/app/components/about/about.component'
import { TeamMemberComponent } from 'src/app/components/about/team-member/team-member.component'
import { ContactComponent } from 'src/app/components/contact/contact.component'
import { ErrorComponent } from 'src/app/shared/components/error/error.component'
import { FooterComponent } from 'src/app/shared/components/footer/footer.component'
import { GlobalErrorComponent } from 'src/app/shared/components/global-error/global-error.component'
import { HeaderComponent } from 'src/app/shared/components/header/header.component'
import { CustomLayoutComponent } from 'src/app/shared/components/layout/custom-layout/custom-layout.component'
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component'
import { SuccessComponent } from 'src/app/shared/components/success/success.component'
import { rootReducer } from 'src/app/store/app.reducer'
import { TranslocoRootModule } from 'src/app/transloco-root.module'

@NgModule({
    declarations: [
        CustomLayoutComponent,
        AppComponent,
        FooterComponent,
        HeaderComponent,
        ContactComponent,
        AboutComponent,
        PageNotFoundComponent,
        TeamMemberComponent,
        GlobalErrorComponent,
    ],
    // providers: [
    //     GlobalErrorHandlerService,
    //     { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    // ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatSidenavModule,
        LayoutModule,
        MatInputModule,
        MatRadioModule,
        MatCardModule,
        MatGridListModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatListModule,
        StoreModule.forRoot({ appState: rootReducer }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !isDevMode,
            autoPause: true,
            trace: true,
        }),
        HttpClientModule,
        TranslocoRootModule,

        // standalone components
        SuccessComponent,
        ErrorComponent,
    ],
})
export class AppModule {}
