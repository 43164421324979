import {
    CurrentUserInterface,
    UserPreferenceInterface,
} from 'src/app/shared/interfaces/currentUser.interface'

export interface AuthStateInterface {
    currentUser: CurrentUserInterface | null
    userPreferences: UserPreferenceInterface | null
    isUserAuthenticated: boolean
}

export const initialAuthState: AuthStateInterface = {
    currentUser: null,
    userPreferences: null,
    isUserAuthenticated: false,
}
