import { createAction, props } from '@ngrx/store'
import { UserAddress } from 'src/app/shared/interfaces/userAddress.interface'
import { AuthStateInterface } from '../../interfaces/authState.interface'

export const loginSuccessAction = createAction(
    '[Login Page] Login Actions Success',
    props<{
        auth: AuthStateInterface
        returnUrl: string
        isLoggedIn: boolean
    }>()
)

export const loadUserAddressAction = createAction(
    '[Login Page] Load User Document Actions',
    props<{ userAddress: UserAddress | null }>()
)

export const logoutUserAction = createAction('[Login Page] Logout user Success')
