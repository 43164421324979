export interface Member {
    name: string
    title: string
    img: string
    links?: {
        github?: string
        linkedin?: string
    }
}

export const members: Member[] = [
    {
        name: 'Khaled Al Jadaan',
        title: 'Senior Developer',
        img: 'assets/images/about/team/Khaled.jpg',
        links: {
            github: 'https://github.com/khaled-300',
            linkedin: 'https://www.linkedin.com/in/khaled-al-jadaan',
        },
    },

    {
        name: 'Mubarak Salih',
        title: 'Front-end Web Developer',
        img: 'assets/images/about/team/Mubarak.jpg',
        links: {
            github: 'https://github.com/mubaraksalih',
            linkedin: 'https://www.linkedin.com/in/mubarak-salih',
        },
    },

    {
        name: 'Mahmoud Aljadan',
        title: 'IT Developer',
        img: 'assets/images/about/team/Mahmoud.jpg',
        links: {
            github: 'https://github.com/mahjadan',
            linkedin: 'https://www.linkedin.com/in/mohamoud-aljadan-972644125',
        },
    },

    {
        name: 'Felipe Barretti',
        title: 'UX/UI Designer',
        img: 'assets/images/about/team/Felipe.jpg',
        links: {
            linkedin: 'https://www.linkedin.com/in/felipebarretti',
        },
    },

    {
        name: 'Hazem Jadaan',
        title: 'English Teacher',
        img: 'assets/images/about/team/Hazem.jpg',
        links: {
            linkedin: 'https://www.linkedin.com/in/hazem-jadaan-b5824931',
        },
    },
]
