import { Component } from '@angular/core'
import { LogType } from './shared/interfaces/log.interface'
import { LogService } from './shared/services/log.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(private logger: LogService) {
        logger.writeLogAsync({
            message: 'Starting masjids ui app!',
            logType: LogType.Debug,
        })
    }
}
