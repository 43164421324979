import { createAction, props } from '@ngrx/store'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'

export const registerMasjidSuccessAction = createAction(
    '[Action] Register Actions Success',
    props<{ masjid: MasjidDataInterface }>()
)

export const loadMasjidList = createAction('[load] Load Masjid List')

export const loadMasjidListSuccess = createAction(
    '[load] Fill the list of masjids',
    props<{ masjids: Array<MasjidDataInterface> }>()
)
export const loadMasjidListFailure = createAction(
    '[load] Load Masjid List Failure',
    props<{ error: any }>()
)
export const viewMasjidDetailsAndNavigate = createAction(
    '[Masjid] show masjid details',
    props<{ masjidId: string }>()
)
