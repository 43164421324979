import { createAction, props } from '@ngrx/store'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'

export const loadMasjidsNews = createAction('[Masjids News] load news')

export const loadMasjidsNewsSuccess = createAction(
    '[Masjids News] load news success',
    props<{
        newsList: NewsInterface[]
    }>()
)

export const loadMasjidsNewsFailure = createAction(
    '[Masjids News] load news failure',
    props<{
        error: any
    }>()
)

export const viewNewsDetailsAndNavigate = createAction(
    '[Masjids News] show news details',
    props<{ news_id: string }>()
)
