import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core'

import { Direction } from '@angular/cdk/bidi'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { DOCUMENT } from '@angular/common'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { Router } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { logoutUserAction } from 'src/app/auth/store/actions/login.actions'
import { currentUserSelector } from 'src/app/auth/store/selectors'
import { languageList } from 'src/app/shared/interfaces/language.interface'
import { AuthService } from 'src/app/shared/services/auth.service'
import { AppState } from 'src/app/store/app.state'
import { CurrentUserInterface } from '../../interfaces/currentUser.interface'
import { APPWRITE_SDK, AppwriteSdk } from '../../services/appwrite.provider'
import { NotificationService } from '../../services/notification.service'
import { StorageService } from '../../services/storage.service'

const LANGUAGE_KEY = 'mi-language'
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    constructor(
        private breakpointObserver: BreakpointObserver,
        @Inject(DOCUMENT) private document: Document,
        private _translocoService: TranslocoService,
        private storageService: StorageService,
        private rerender: Renderer2,
        @Inject(APPWRITE_SDK)
        private _appwrite: AppwriteSdk,
        private _store: Store<AppState>,
        private _authService: AuthService,
        private _router: Router,
        private _notification: NotificationService
    ) {
        this.currentUser$ = this._store.pipe(select(currentUserSelector))
        this.userLoggedIn = this._authService.isLoggedIn
    }

    currentUser$: Observable<CurrentUserInterface | null>
    userLoggedIn: Observable<boolean>
    @Output()
    toggleSideNavEvent = new EventEmitter()

    @Output()
    direction = new EventEmitter<Direction>()

    ngOnInit(): void {
        let value = this.storageService.getItem(LANGUAGE_KEY)
        if (value) {
            let langInfo = JSON.parse(value)
            console.log('langInfo', langInfo.code)
            this.languagePicked(langInfo.code)
        }
    }

    toggleSideNav() {
        this.toggleSideNavEvent.emit()
    }

    logout() {
        console.log('button is clicked')
        this._store.dispatch(logoutUserAction())
    }

    languages = languageList

    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        )

    languagePicked(code: string) {
        if (code == 'ar') this.direction.emit('rtl')
        else this.direction.emit('ltr')

        this._translocoService.setActiveLang(code)
        this.storageService.setItem(
            LANGUAGE_KEY,
            JSON.stringify({ code: code })
        )
        // supported locales https://github.com/appwrite/appwrite/blob/master/app/config/locale/codes.php
        if (code === 'pt') {
            code = 'pt-br'
        }
        this._appwrite.locale.client.setLocale(code)
    }

    switchMode({ checked: isDarkMode }: MatSlideToggleChange) {
        const hostClass = isDarkMode ? 'theme-dark' : 'theme-light'

        const backgroundImage = isDarkMode
            ? 'background-dark-image'
            : 'background-light-image'

        if (isDarkMode) {
            this.rerender.removeClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-light-image'
            )
            this.rerender.addClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-dark-image'
            )
        } else {
            this.rerender.removeClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-dark-image'
            )
            this.rerender.addClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-light-image'
            )
        }

        this.rerender.setAttribute(this.document.body, 'class', hostClass)
    }
}
