import { Action, createReducer, on } from '@ngrx/store'
import { loadMasjidListFailure } from 'src/app/masjids/store/actions/masjid.actions'
import {
    NewsStateInterface,
    initialNewsState,
} from '../interfaces/news.state.interface'
import { loadMasjidsNewsSuccess } from './actions/news.actions'

const newsReducer = createReducer<NewsStateInterface>(
    initialNewsState,
    on(
        loadMasjidsNewsSuccess,
        (state, action): NewsStateInterface => ({
            ...state,
            newsList: action.newsList,
        })
    ),
    on(
        loadMasjidListFailure,
        (state, action): NewsStateInterface => ({
            ...state,
            error: action.error,
        })
    )
)

export function reducerNews(
    state: NewsStateInterface | undefined,
    action: Action
) {
    return newsReducer(state, action)
}
