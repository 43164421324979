import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { throwError } from 'rxjs'
import { catchError } from 'rxjs/internal/operators/catchError'
import { map } from 'rxjs/internal/operators/map'
import {
    LogEntry,
    LogResponse,
    LogType,
} from 'src/app/shared/interfaces/log.interface'
import { environment } from 'src/environments/environment'
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class LogService {
    constructor(private _httpClient: HttpClient) {}

    public writeLogAsync(logEntry: LogEntry): void {
        if (!this.validateLogEntry(logEntry)) {
            console.log('LogEntry is not valid!')
            return
        }

        this._httpClient
            .post<LogResponse>(environment.logApiUrl, logEntry, {
                responseType: 'json',
            })
            .pipe(
                map((response) => {
                    if (response.message) {
                        console.info('Log entry created!', response)
                    } else {
                        console.error(
                            'Response does not contain a message.',
                            response
                        )
                    }
                }),
                catchError((error: any) => {
                    console.error('Error calling log API:', error)
                    return throwError(() => error)
                }),
                untilDestroyed(this)
            )
            .subscribe()
    }

    public validateLogEntry(LogEntry: LogEntry): boolean {
        if (!LogEntry.message || LogEntry.message.trim() === '') {
            throw new Error('Message is empty or invalid')
        }

        if (
            LogEntry.logType == null ||
            !Object.values(LogType).includes(LogEntry.logType)
        ) {
            throw new Error('Invalid log type')
        }

        return true
    }
}
