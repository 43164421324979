import { Injectable } from '@angular/core'
import { TranslocoService } from '@ngneat/transloco'
import * as Notiflix from 'notiflix'

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private _transloco: TranslocoService) {}

    loading() {
        Notiflix.Loading.standard('Loading...', {
            backgroundColor: 'rgba(0,0,0,0.8)',
            svgColor: '#f5b339',
        })
    }

    removeLoading() {
        Notiflix.Loading.remove()
    }

    notifyFailure(message: string) {
        Notiflix.Notify.failure(message, {
            position: this.ltrPosition(),
        })
    }

    notifySuccess(message: string) {
        Notiflix.Notify.success(message, {
            position: this.ltrPosition(),
        })
    }

    notifyWarning(message: string) {
        Notiflix.Notify.warning(message, {
            position: this.ltrPosition(),
        })
    }

    notifyInfo(message: string) {
        Notiflix.Notify.info(message, {
            position: this.ltrPosition(),
        })
    }

    notifyConfirm(
        title: string,
        message: string,
        okButtonText: string,
        cancelButtonText?: string,
        okButtonCallback?: () => void,
        cancelButtonCallback?: () => void,
        options?: Notiflix.IConfirmOptions
    ) {
        Notiflix.Confirm.show(
            title,
            message,
            okButtonText,
            cancelButtonText,
            okButtonCallback,
            cancelButtonCallback,
            options
        )
    }

    ltrPosition() {
        return this._transloco.getActiveLang() === 'ar'
            ? 'left-top'
            : 'right-top'
    }
}
