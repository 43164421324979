<footer class="footer">
  <mat-grid-list cols="1" rowHeight="fit" style="height: 200px">
    <mat-grid-tile
      ><img
        class="logo-footer"
        src="assets/images/logo/MasjidsInfowhite.svg"
        alt="masjids info logo"
    /></mat-grid-tile>
    <mat-grid-tile
      >CNPJ 35.315.915/0001-70 - Rua piranchin nº207 Santo Amaro, São Paulo, SP,
      Brazil</mat-grid-tile
    >
    <mat-grid-tile>&copy;Masjidsinfo - All rights reserved</mat-grid-tile>
    <mat-grid-tile
      >Application version: {{ appVersion }}</mat-grid-tile
    >
  </mat-grid-list>
  <img
    id="nav-arrow"
    src="assets/images/nav-arrow.svg"
    alt="Scroll to Top"
    (click)="scrollToTop()"
  />
</footer>
