export const languageList: Language[] = [
    {
        country: 'SA',
        code: 'ar',
        nativeName: 'العربية',
    },
    {
        country: 'US',
        code: 'en',
        nativeName: 'English',
    },
    {
        country: 'ES',
        code: 'es',
        nativeName: 'Español',
    },
    {
        country: 'BR',
        code: 'pt',
        nativeName: 'Português',
    },
]

interface Language {
    country: string
    code: string
    nativeName: string
}
