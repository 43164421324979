import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState } from 'src/app/store/app.state'

export const selectAppState = createFeatureSelector<AppState>('appState')

export const selectAuthState = createSelector(
    selectAppState,
    (state) => state.auth
)

export const selectEditMasjidState = createSelector(
    selectAppState,
    (state) => state.editMasjid
)

export const selectMasjidState = createSelector(
    selectAppState,
    (state) => state.masjid
)

export const selectMasjidsNews = createSelector(
    selectAppState,
    (state) => state.news
)
