<mat-card class="error-card">
  <mat-card-header class="flex-center">
    <mat-card-title>
      <mat-error>
        <h1>Error! <mat-icon>error</mat-icon></h1>
      </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="flex-center">
    <div class="row" style="margin-top: 1.5rem">
      <div class="col">
        <mat-error>
          An error occurred while processing your request, please report the
          issue or try again later.
        </mat-error>
      </div>
    </div>

    <div class="row" style="margin-top: 1.5rem" *ngIf="hasErrorDetails">
      <div class="col">
        <p>Error details:</p>
        <p *ngIf="error.message != ''">message: {{ error.message }}</p>
        <p *ngIf="error.statusCode > 0">stats code: {{ error.statusCode }}</p>
        <p *ngIf="error.stackTrace != ''">stack: {{ error.stackTrace }}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
