import { createAction, props } from '@ngrx/store'
import { AuthenticatedInterface } from 'src/app/shared/interfaces/authenticated.interface'
import {
    CurrentUserInterface,
    UserPreferenceInterface,
} from 'src/app/shared/interfaces/currentUser.interface'

export const refreshCurrentUserAction = createAction(
    '[Authenticated Guard] Refresh Current User',
    props<{ currentUser: CurrentUserInterface | null }>()
)
export const refreshUserPreferencesAction = createAction(
    '[Authenticated Guard] Refresh User Preferences',
    props<{ userPreferences: UserPreferenceInterface | null }>()
)
export const refreshAuthenticatedUserDataAction = createAction(
    '[Authenticated Guard] Refresh authenticated user data',
    props<{ auth: AuthenticatedInterface }>()
)
