import { Component, Input } from '@angular/core'

import { Member } from '../Members'

@Component({
    selector: 'app-team-member',
    templateUrl: './team-member.component.html',
    styleUrls: ['./team-member.component.scss'],
})
export class TeamMemberComponent {
    @Input() member!: Member
}
