<mat-card class="member-card">
  <img mat-card-image src="{{ member.img }}" alt="Photo of {{ member.name }}" />
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>
        <span>{{ member.name }}</span>
      </mat-card-title>
      <mat-card-subtitle>{{ member.title }}</mat-card-subtitle>
      <span *ngIf="member.links" class="member-card-links">
        <a
          *ngFor="let link of member.links | keyvalue"
          href="{{ link.value }}"
          target="_blank"
        >
          <img
            class="icon"
            src="assets/images/{{ link.key }}.svg"
            alt="{{ link.key }} icon"
          />
        </a>
      </span>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content></mat-card-content>
</mat-card>
