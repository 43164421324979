<mat-card class="contact-card" *transloco="let t; read: 'contact'">
  <form [formGroup]="formData" (ngSubmit)="onSubmit($event)">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">{{ t('title') }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <!-- Name -->
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ t('nameLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('nameLabel') }}"
              formControlName="name"
              required
            />
            <mat-error *ngIf="formData.controls['name'].hasError('required')">
              {{ t('nameLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Email -->
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ t('emailLabel') }}</mat-label>
            <input
              matInput
              type="email"
              placeholder="example@mail.com"
              formControlName="emailAddress"
              required
            />
            <mat-error
              *ngIf="formData.controls['emailAddress'].hasError('email')"
            >
              {{ t('emailLabel') + ' ' + t('errorEmailInvalid') }}
            </mat-error>
            <mat-error
              *ngIf="formData.controls['emailAddress'].hasError('required')"
            >
              {{ t('emailLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Message -->
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('messageLabel') }}</mat-label>
            <textarea
              matInput
              type="text"
              placeholder="{{ t('messageLabel') }}"
              formControlName="message"
            >
            </textarea>
            <mat-error
              class="error"
              *ngIf="formData.controls['message'].hasError('required')"
            >
              {{ t('messageLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-success [message]="successMessage"></app-success>
          <app-error [message]="errorMessage"></app-error>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="flex-center full-width pi-16px">
      <div class="row flex-center">
        <button
          type="submit"
          [disabled]="isSubmitting$ | async"
          mat-raised-button
          color="accent"
        >
          {{ t('submitLabel') }}
        </button>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
