import { createAction, props } from '@ngrx/store'
import { Observable } from 'rxjs'
import { PhotoOperation } from '../../interfaces/masjid.state.interface'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
} from '../../interfaces/masjidData.interface'

export const updateExistingMasjid = createAction('[UpdateMasjid] action')

export const updateExistingMasjidSuccess = createAction(
    '[UpdateMasjid] Success',
    props<{
        updated: boolean
        masjidData: MasjidDataInterface
        isSubmittingSave: boolean
    }>()
)

export const updateExistingMasjidFailure = createAction(
    '[UpdateMasjid] Failure',
    props<{
        isSubmittingSave: boolean
        updated: boolean
        error: any
    }>()
)

export const setIsSubmittingSave = createAction(
    '[EditMasjid] Set Is Submitting Save',
    props<{ save: boolean }>()
)
export const setLessons = createAction(
    '[EditMasjid] Set Lessons',
    props<{
        newLessons: MasjidLessonsInterface[]
        updateLessons: MasjidLessonsInterface[]
        deleteLessons: MasjidLessonsInterface[]
    }>()
)
export const setMasjidData = createAction(
    '[EditMasjid] Set Masjid Data',
    props<{ data: { [key: string]: any } }>()
)
export const setDeleteFiles = createAction(
    '[EditMasjid] Set Delete Files',
    props<{
        files: Observable<PhotoOperation>[]
    }>()
)
export const setUploadFiles = createAction(
    '[EditMasjid] Set Upload Files',
    props<{
        files: Observable<PhotoOperation>[]
    }>()
)

export const setDeleteLessons = createAction(
    '[EditMasjid] Set Delete Lessons',
    props<{
        files: Observable<PhotoOperation>[]
    }>()
)

export const resetFiles = createAction('[EditMasjid] Reset Files')
