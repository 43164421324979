<div
  class="about"
  [style]="
    (isHandset$ | async)
      ? 'padding-block: 2rem; gap:2rem;'
      : 'padding-block: 4rem; gap:4rem;'
  "
>
  <section class="about-hero">
    <img
      class="about-hero-img"
      src="../../../assets/images/about/about-hero.jpg"
      alt="Photo of a mosque"
    />
    <h2
      class="about-hero-title"
      [style]="(isHandset$ | async) ? 'padding: 1rem;' : 'padding: 2rem;'"
    >
      About us
    </h2>
  </section>

  <section
    class="about-info"
    [style]="
      (isHandset$ | async)
        ? 'margin-top:2rem; gap:1rem;'
        : 'margin-top:4rem; gap:2rem;'
    "
  >
    <div [style]="!(isHandset$ | async) && 'flex-direction: row-reverse;'">
      <img
        src="../../../assets/images/about/about-img-1.jpg"
        alt="Photo of a mosque"
      />
      <p>
        We are a group of developers who has the idea of getting together and
        doing something that makes the life of Muslims easier, Both residents
        and tourists.<br /><br />
        By gathering information about masjids around the world, we are able to
        spread information in unique and objective way.
      </p>
    </div>
    <div [style]="!(isHandset$ | async) && 'flex-direction: row;'">
      <img
        src="../../../assets/images/about/about-img-2.jpg"
        alt="Photo of a mosque"
      />
      <p>
        By gathering information about masjids around the world, we are able to
        spread information in unique and objective way.
      </p>
    </div>
  </section>

  <section
    class="about-team"
    [style]="(isHandset$ | async) ? 'margin-top:2rem;' : 'margin-top:4rem;'"
  >
    <h3>Participants of this project</h3>

    <div class="about-team-members">
      <app-team-member
        *ngFor="let member of members"
        [member]="member"
      ></app-team-member>
    </div>
  </section>
</div>
