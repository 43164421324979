export enum LogType {
    Error = 0,
    Warn = 1,
    Info = 2,
    Http = 3,
    Verbose = 4,
    Debug = 5,
    Silly = 6,
}

export interface LogResponse {
    message: string
    error: any
}

export interface LogEntry {
    message: string
    logType: LogType | null
}
