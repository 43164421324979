import { combineReducers } from '@ngrx/store'
import { reducerAuth } from '../auth/store/reducers'
import { reducerEditMasjid } from '../masjids/store/reducers/edit-masjid.reducer'
import { reducerMasjid } from '../masjids/store/reducers/masjid.reducer'
import { reducerNews } from '../news/store/reducers'

export const rootReducer = combineReducers({
    auth: reducerAuth,
    editMasjid: reducerEditMasjid,
    masjid: reducerMasjid,
    news: reducerNews,
})
