import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { GlobalError } from 'src/app/shared/interfaces/global-error.interface'

@Component({
    selector: 'app-global-error',
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.scss'],
})
export class GlobalErrorComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}
    error: GlobalError
    hasErrorDetails: boolean = false
    ngOnInit(): void {
        this.route.queryParams.subscribe((params: any) => {
            if (Object.keys(params).length > 0 && params != undefined) {
                console.log(params)
                this.hasErrorDetails = true
                this.error = params
            }
        })
    }
}
